import React, {Fragment} from 'react';

const LoadingSpinner = (props) => {
    return ( 
        <Fragment>
            <div className="dots">
                <div className="dot dot-1"></div>
                <div className="dot dot-2"></div>
                <div className="dot dot-3"></div>
            </div>
        </Fragment>
     );
}
 
export default LoadingSpinner;