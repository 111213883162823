import React,{Component, Fragment} from 'react';

class Tableau extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            tab : this.props.cursus,
         }
        this.myRef = React.createRef(); 
    }

    componentDidMount(){
        const liens = this.myRef.current.querySelectorAll('.nav-tabs li')
        
        for(const lien of liens){
            lien.addEventListener('click',(event)=>{
                event.preventDefault()
                const act = this.myRef.current.querySelector('.nav-tabs .active')
                act.classList.remove('active')
                lien.classList.toggle('active')
                const link = lien.querySelector('a').getAttribute("href")
                const tabAct = this.myRef.current.querySelector('.tab-content .active')
                tabAct.classList.remove('active')
                const linkAct = this.myRef.current.querySelector(link)
                linkAct.classList.add('active')
            })
        }
       
    }

    render() { 
        return ( 
            <Fragment>
                <article ref={this.myRef} dangerouslySetInnerHTML={{__html:this.state.tab}}>
                   
                </article>

            </Fragment>
         );
    }
}
 
export default Tableau;