import React,{Fragment} from 'react';
import { Link } from 'react-router-dom'

const NotFound = ({location}) => {
    return ( 
        <Fragment>
            <div className="not-found">
                <div className="container">
                    <h1>DÉSOLÉ</h1>
                    <h2>La page : {location.pathname } que vous cherchez n'existe pas!</h2>
                    <h3>Reformulez votre URL ou allez sur <Link to="/">la page d'accueil</Link></h3>
                </div>

            </div>
        </Fragment>
     );
}
 
export default NotFound;