import React, {useState, Fragment, useEffect, useContext}  from 'react'
import { toast } from 'react-toastify';
import Axios from 'axios';
import Tableau from '../components/Tableau'
import { Link } from "react-router-dom";
import MenuContext from '../contexts/MenuContext';

const Formation3 = (props) => {
    const [formation, setFormation] = useState([])
    var slug = props.match.params.slug
    const {setMenuSelect, showtimeOn} = useContext(MenuContext)
    
    const handleClick = (path) => {
        setMenuSelect(path)
    }

    const fetchFormation = async slug => {
        try{
            const data = await Axios.get(`https://adminepse.jordanberti.com/api/formations?slug=${slug}`)
                                    .then(response => response.data['hydra:member'])
            if(data.length <= 0)
            {
                props.history.replace('/')
            }
            setFormation(data)                        
        }catch(error){
            toast.error('impossible de charger la formation')
            console.log(error)
            //props.history.replace('/')
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0)
        fetchFormation(slug)
    },[slug])

   

    return ( 
        <div id="formation-page">
            {
                formation.map( (cursus, key) => 
                    <Fragment key={key}>
                        <section className={"head" + (showtimeOn==="yes" ? " stof" : "")} style={{backgroundColor: cursus.color}}>
                            <div className="container d-flex">
                                <div className="formation-info">
                                    <h1>{cursus.title}</h1>
                                    <article>
                                        {cursus.introduction.split('\n').map((item, key) => { 
                                            return <Fragment key = {key}> {item} <br/> </Fragment> 
                                        })}
                                    </article>
                                </div>
                                <aside><img src={`https://adminepse.jordanberti.com/uploads/logo/${cursus.logo}`} alt={`Logo de ${cursus.title}`}/></aside>
                            </div>
                        </section>
                        <section className="formation-body">
                            <div className="container d-flex">
                                <div id="body-left">
                                    <div className="condition">
                                        <h3>CONDITIONS D'ADMISSION</h3>
                                        <span dangerouslySetInnerHTML={{__html:cursus.admission}}></span>
                                    </div>
                                    <div className="start">
                                        <h3>DÉBUT DE LA FORMATION</h3>
                                        {/*cursus.start.split('\n').map((item, key) => { 
                                            return <Fragment key = {key}> {item} <br/> </Fragment> 
                                        })*/}
                                        <span dangerouslySetInnerHTML={{__html:cursus.start}}></span>
                                    </div>
                                    <div className="horaire">
                                            <h3>HORAIRE DES COURS</h3>
                                            <span dangerouslySetInnerHTML={{__html:cursus.horaire}}></span>
                                    </div>
                                    <div className="diplome">
                                            <h3>TITRE DÉLIVRÉ</h3>
                                            <span dangerouslySetInnerHTML={{__html:cursus.titre}}></span>
                                    </div>
                                    <div className="tarif">
                                            <h3>TARIFS</h3>
                                            <span dangerouslySetInnerHTML={{__html:cursus.tarif}}></span>
                                    </div>
                                    <div className="pdf">
                                        
                                        <a href={`https://adminepse.jordanberti.com/uploads/pdf/${cursus.pdf}`}>DOCUMENTATION PDF <i className="fas fa-download"></i></a>
                                        <Link to="/inscription" onClick={()=>handleClick('inscription')}>COMMENT M'INSCRIRE?</Link>
                                    </div>
                                    
                                </div>
                                <div id="body-right">
                                    <div className="programme">
                                            <h3>PROGRAMME DE LA FORMATION</h3>
                                            <Tableau cursus={cursus.programme} />
                                    </div>
                                    {
                                        (cursus.debouche!=="-") && (
                                            <div className="debouche">
                                                <h3>DÉBOUCHÉS</h3>
                                                <ul>
                                                    {cursus.debouche.split('\n').map((item, key) => { 
                                                        return <li key={key}>{item}</li> 
                                                    })}
        
                                                </ul>
                                            </div> 
                                        )
                                    }
                                   
                                </div>
                            </div>
                        </section>   
                    </Fragment>
                )
            }
            </div>
     );
}
 
export default Formation3;