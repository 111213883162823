import React, {Fragment} from 'react';

const ShowInfosPdf = (props) => {
    return ( 
        <Fragment>
           <div className="infos">
               {
                   props.pdfs.map((pdf, key)=>
                       <div key={key} className="infos-pdf">
                            <h3 className="infos-pdf-title">{pdf.title}</h3>
                            <a href={`https://adminepse.jordanberti.com/uploads/pdf/${pdf.pdf}`} className="infos-file">
                                FICHIER PDF <i className="fas fa-download"></i>
                            </a>       
                       </div>
                   )
               }
           </div> 
        </Fragment>
     );
}
 
export default ShowInfosPdf;