import React from "react";
import styled from "styled-components";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import HomePage from '../pages/HomePage'
import Formations from '../pages/Formations';
//import Formation from '../pages/Formation'
import Formation2 from '../pages/Formation2'
import Formation3 from '../pages/Formation3'
import Contact from '../pages/Contact'
import Inscription from '../pages/Inscription'
import Informations from '../pages/Informations'
import NotFound from '../pages/NotFound'
import Footer from "./Footer";
import Actu from "../pages/Actu";
import Article from "../pages/Article";
import Events from "../pages/Events";
import Event from "../pages/Event";
import Job from "../pages/Job";
import Jobs from "../pages/Jobs";
import Propos from "../pages/Propos";
import Legal from "../pages/Legal";
import Qualites from "../pages/Qualites";
import EnseignementSup from "../pages/EnseignementSup"
import EnseignementQual from '../pages/EnseignementQual'
import PartnersPage from "../pages/PartnersPage";
import PortesOuvertes from '../pages/PortesOuvertes'


function Container({ location }) {
  return (
    <Wrapper>
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={location.key}
          timeout={{ enter: 500, exit: 500 }}
          classNames="fade"
        >
          <section className="route-section">
            <Switch location={location}>
                <Route exact path="/" component={HomePage} />
                <Route path="/enseignement-qualifiant" component={EnseignementQual} />
                <Route path="/enseignement-superieur" component={EnseignementSup} />
                <Route path="/qualite" component={Qualites} />
                <Route path="/legal" component={Legal} />
                <Route path="/a-propos" component={Propos} />
                <Route exact path="/emploi/:slug/" component={Job} />
                <Route path="/emploi" component={Jobs} />
                <Route path="/evenements/:id" component={Event} />
                <Route path="/evenements" component={Events} />
                <Route path="/actualites/:id" component={Article} />
                <Route path="/actualites" component={Actu} />
                <Route exact path="/formation/:slug/" component={Formation3} />
                <Route path="/formations/:slug" component={Formations} />
                <Route path="/formations" component={Formations} />
                <Route path="/inscription" component={Inscription} />
                <Route path="/informations" component={Informations} />
                <Route path="/partenaires" component={PartnersPage} />
                <Route path="/contact" component={Contact} />
                <Route component={NotFound} />
            </Switch>
            <Footer />    
          </section>
        </CSSTransition>
      </TransitionGroup>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    // omitted
    
      div.transition-group {
           position: relative;
      }
      section.route-section {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index:2;
      }
`;

export default withRouter(Container);