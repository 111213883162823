import React,{useState, useEffect, useContext} from 'react'
import Axios from "axios"
import moment from 'moment';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import LoadingSpinner from "../components/LoadingSpinner"
import MenuContext from '../contexts/MenuContext';

const Events = (props) => {
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState([])
    const {showtimeOn} = useContext(MenuContext)

    useEffect(() => {
        setLoading(true)
        window.scrollTo(0,0);
        Axios
            .get(`https://adminepse.jordanberti.com/api/events?date[after]=${moment().format('YYYY/MM/DD')}`)
            .then(response => response.data['hydra:member'])
            .then(data => {
                setEvents(data)
                setLoading(false)
            })
            .catch(error => {
                toast.error("Un problème est survenu")
                props.history.replace('/')
            })
    }, []);

    const months = ['JANVIER','FÉVRIER','MARS','AVRIL','MAI','JUIN','JUILLET','AOÛT','SEPTEMBRE','OCTOBRE','NOVEMBRE','DÉCEMBRE']
    const formatMonth = (str) => {
        let myDate = new Date(str)
        return months[myDate.getMonth()]
    }
    const formatDay = (str) => {
        let myDay = new Date(str)
        return myDay.getDate()
    }

    const formatYear = (str) => {
        let myYear = new Date(str)
        return myYear.getFullYear()
    }

    const myMore = (event) => {
        if(event.content == null && event.link == null){
            return ``
        }else if(event.content != null){
            return (
                <Link to={`/evenements/${event.id}`}>Voir contenu</Link>
            )
        }else{
            return (
                <a href={event.link}>Voir le lien</a>
            )
        }
    }

    return ( 
        <>
             <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>Les Évènements</h1>
                    <p>Liste des évènements de l'EPSE</p>
                    <Link to="/">Retour à la page d'accueil</Link>
                </div>
            </div>
            <div className="page-body" >
                <div className="container" id="all-events">
                { loading ? <LoadingSpinner /> : (events.map(event =>
                      
                      <div className="event" key={event.id}>
                          <div className="event-date">
                            <h3>{formatDay(event.date)} {formatMonth(event.date)}  {formatYear(event.date)}</h3>
                          </div>
                          <div className="title">{event.title}</div>
                          <div className="btn">{myMore(event)}</div>
                      </div>
                      
                  ))}
                </div>
            </div>    
        </>
     );
}
 
export default Events;