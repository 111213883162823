import React, { Component,Fragment } from 'react';
import { Link } from "react-router-dom";

class ShowFormations extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <Fragment>
                {
                    this.props.formations.map( (formations, key) => 
                    <div className="formation" key={key} >
                        <Link to={`/Formation/${formations.slug}`}>
                        <div className="f-title" style={{ backgroundColor: formations.color}}>{formations.title}</div>
                        <div className="f-back"><img src={"https://adminepse.jordanberti.com/uploads/cover/"+formations.cover} alt={"image de "+formations.title}/></div>
                        </Link>
                    </div>
                    
                    )
                }
            </Fragment>
         );
    }
}
 
export default ShowFormations;