import React, { useContext } from 'react'
import MenuContext from '../contexts/MenuContext';

const Propos = (props) => {
    const {showtimeOn} = useContext(MenuContext)
    window.scrollTo(0,0)
    return ( 
        <>
            <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>A propos</h1>
                    <p>L'EPSE est un établissement de promotion sociale reconnu et subsidié par la Fédération Wallonie-Bruxelles; membre de la Fédération des Etablissements Libres Subventionnés Indépendants</p>
                </div>
            </div>
            <div className="page-body" >
                <div className="container about">
                    <p>
                     Fondée officiellement le <strong>10 janvier 1912</strong>, l’Ecole industrielle et commerciale d’Enghien est devenue le <strong>17 octobre 1995 l’Enseignement de Promotion Sociale d’Enghien (E.P.S.E).</strong>
                    </p>
                    <p>
                    C’est en 1907 qu’à l’initiative de quelques comptables, s’organisent les premiers cours. Quelques années plus tard on songe à une nouvelle section. Le 10 janvier 1912 c’est chose faite, et, le 01 octobre de la même année, l’Ecole est officiellement créée.
                    </p>
                    <p>
                    Les fondateurs en sont <strong>Pierre Delannoy</strong>, <strong>Louis Isaac</strong>, <strong>Jules Devrout</strong>, comptable, et <strong>Emile Pacco</strong>, avocat.
                    </p>



                </div>
            </div>   
        </>
     );
}
 
export default Propos;