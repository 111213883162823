import React, {Fragment} from 'react';
import axios from 'axios'
import Tableau from '../components/Tableau'
import { Link } from "react-router-dom";

class Formation2 extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        formation: [],
        slug : this.props.match.params.slug
    }

    componentDidMount(){
        window.scrollTo(0,0)
        axios
            .get(`http://adminepse.jordanberti.com/api/formations?slug=${this.state.slug}`)
            .then(response => response.data['hydra:member'])
            .then(data => this.setState({formation:data}))
            .catch(error=>console.log(error.response))
    }


    render() { 
        return ( 
                <div id="formation-page">
            {
                this.state.formation.map( (cursus, key) => 
                    <Fragment key={key}>
                        <section className="head" style={{backgroundColor: cursus.color}}>
                            <div className="container d-flex">
                                <div className="formation-info">
                                    <h1>{cursus.title}</h1>
                                    <article>
                                        {cursus.introduction.split('\n').map((item, key) => { 
                                            return <Fragment key = {key}> {item} <br/> </Fragment> 
                                        })}
                                    </article>
                                </div>
                                <aside><img src={`http://adminepse.jordanberti.com/uploads/logo/${cursus.logo}`} alt={`Logo de ${cursus.title}`}/></aside>
                            </div>
                        </section>
                        <section className="formation-body">
                            <div className="container d-flex">
                                <div id="body-left">
                                    <div className="condition">
                                        <h3>CONDITONS D'ADMISSION</h3>
                                        {cursus.admission}
                                    </div>
                                    <div className="start">
                                        <h3>DÉBUT DE LA FORMATION</h3>
                                        {cursus.start.split('\n').map((item, key) => { 
                                            return <Fragment key = {key}> {item} <br/> </Fragment> 
                                        })}
                                    </div>
                                    <div className="horaire">
                                            <h3>HORAIRE DES COURS</h3>
                                            {cursus.horaire}
                                    </div>
                                    <div className="diplome">
                                            <h3>TITRE DÉLIVRÉ</h3>
                                            {cursus.titre}
                                    </div>
                                    <div className="tarif">
                                            <h3>TARIFS</h3>
                                            {cursus.tarif}
                                    </div>
                                    <div className="pdf">
                                        
                                        <a href={`http://adminepse.jordanberti.com/uploads/pdf/${cursus.pdf}`}>DOCUMENTATION PDF <i className="fas fa-download"></i></a>
                                        <Link to="/inscription">COMMENT M'INSCRIRE?</Link>
                                    </div>
                                    
                                </div>
                                <div id="body-right">
                                    <div className="programme">
                                            <h3>PROGRAMME DE LA FORMATION</h3>
                                            <Tableau cursus={cursus.programme} />
                                    </div>
                                    {
                                        (cursus.debouche!=="-") && (
                                            <div className="debouche">
                                                <h3>Les débouchés</h3>
                                                <ul>
                                                    {cursus.debouche.split('\n').map((item, key) => { 
                                                        return <li key={key}>{item}</li> 
                                                    })}
        
                                                </ul>
                                            </div> 
                                        )
                                    }
                                   
                                </div>
                            </div>
                        </section>   
                    </Fragment>
                )
            }
            </div>
         );
    }
}
 
export default Formation2;