import React, {useEffect, useState, useContext} from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import LoadingSpinner from "../components/LoadingSpinner"
import MenuContext from '../contexts/MenuContext'


const Jobs = (props) => {
    const [myJobs, setMyJobs] = useState([])
    const [loading, setLoading] = useState([])
    const {showtimeOn} = useContext(MenuContext)
    const fetchJobs = async () => {
        try{
            const data = await Axios.get('https://adminepse.jordanberti.com/api/jobs')
                                    .then(response => response.data['hydra:member'])
            setLoading(false)                         
            setMyJobs(data)                        
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        setLoading(true) 
        window.scrollTo(0,0)
        fetchJobs()
    },[])
    


    return ( 
        <>
            <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>Recrutement</h1>
                    <p>Recrutement et constitution d'une réserve de recrutement de chargés de cours. Ces emplois sont à conférer par priorité en fonction principale (A.R. du 20 juillet 1982).</p>
                </div>
            </div>
            <div className="page-body" >
                <div className="container" id="jobs">
                    {loading ? <LoadingSpinner /> :
                        myJobs.length !==0 ? (
                            myJobs.map((job)=>(
                                <Link to={"/emploi/"+job.slug} key={job.id} className="jobs">
                                    <h1>{job.section}</h1>
                                    <h3>{job.title}</h3>
                                </Link>
                            ))
                        ) : (
                            <h1>Aucune offre disponible</h1>
                        )
                    }
                </div>
            </div>   
        </>   
     );
}
 
export default Jobs;