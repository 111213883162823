import React, { useState } from 'react';
import './App.css';
import './index.css';

import Header from './components/Header'

import { BrowserRouter as Router} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Container from './components/Container'
import MenuContext from './contexts/MenuContext';
import CookieConsent from "react-cookie-consent";

function App() {
  const [ menuSelect, setMenuSelect ] = useState('')
  const [ showtimeOn, setShowtimeOn ] = useState('')

  const contextValue = {
    menuSelect : menuSelect,
    setMenuSelect : setMenuSelect,
    showtimeOn: showtimeOn,
    setShowtimeOn: setShowtimeOn
  }

  return (
    <Router>
      <MenuContext.Provider value={contextValue}>
        <Header />
        <Container />
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
        <CookieConsent
          location="bottom"
          buttonText="J'accepte"
          cookieName="ConsentEPSECookies"
          style={{ background: "#2c2d59", zIndex: 2147483647 }}
          buttonStyle={{ background: "#ffffff", color: "#2c2d59", fontSize: "13px" }}
          expires={150}
        >
          Ce site utilise des cookies afin de faciliter la navigation, de permettre l'interaction avec les réseaux sociaux et de réaliser des rapports d'audience anonymisés. En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de ceux-ci.
        </CookieConsent>
      </MenuContext.Provider>
    </Router>
  );
}

export default App;
