import React, {Fragment, useEffect, useState, useContext} from 'react';
import axios from "axios"
import moment from 'moment';
import {
    Link
  } from "react-router-dom";
import MenuContext from '../contexts/MenuContext';
import { toast } from 'react-toastify';
import FeatureImage from '../components/FeatureImage';

const HomePage = (props) => {

    const [events, setEvents] = useState([]);
    const {setMenuSelect, showtimeOn} = useContext(MenuContext)
    const [actu, setActu] = useState([])

    const fetchActu = async () => {
        try{
           const data = await axios.get('https://blogepse.jordanberti.com/wp-json/wp/v2/posts/?per_page=3&_embed') 
                                    .then(response=>response.data)
            setActu(data)                        
        }catch(error){
            toast.error('impossible de charger les informations')
            console.log(error)
        }
    }
    

    const handleClick = (path) => {
        setMenuSelect(path)
    }

    
    useEffect(() => {
        setMenuSelect('home')
        fetchActu()
        window.scrollTo(0,0);
        axios
            .get(`https://adminepse.jordanberti.com/api/events?date[after]=${moment().format('YYYY/MM/DD')}&pagination=true&itemsPerPage=4`)
            .then(response => response.data['hydra:member'])
            .then(data => setEvents(data))
            .catch(error => console.log(error.response))
    }, []);

    const months = ['JAN','FEV','MARS','AVR','MAI','JUIN','JUIL','AOUT','SEPT','OCT','NOV','DEC']
    const formatMonth = (str) => {
        let myDate = new Date(str)
        return months[myDate.getMonth()]
    }
    const formatDay = (str) => {
        let myDay = new Date(str)
        return myDay.getDate()
    }
    //const formatDate = (str) => moment(str).format('DD/MM/YYYY')

    const myMore = (event) => {
        if(event.content == null && event.link == null){
            return ``
        }else if(event.content != null){
            return (
                <Link to={`/evenements/${event.id}`}>Voir contenu</Link>
            )
        }else{
            return (
                <a href={event.link}>Voir le lien</a>
            )
        }
    }
   
    return (
        <Fragment>
            <div id="home">
                <div className={"title" + (showtimeOn==="yes" ? " sto stos" : "")}>
                    <img id="cube" src="/images/2024-OI-GIF-PC.gif" alt="EPSE Ouverture des inscriptions dès le lundi 22 avril" />
                    <img id="mobile-cube" src="/images/2024-OI-GIF-GSM.gif" alt="EPSE Ouverture des inscriptions dès le lundi 22 avril" />
                    <h1>ENSEIGNEMENT DE PROMOTION SOCIALE D'ENGHIEN</h1>
                    <h3>L'EPSE est un établissement reconnu et subsidié par la Fédération Wallonie-Bruxelles<br /> Membre de la Fédération des Etablissements Libres Subventionnés Indépendants</h3>
                </div>
                <div id="organisation">
                    <Link to="/enseignement-superieur" onClick={()=>handleClick('Formations')} className="promo">
                        <div id="sup" className="promo-img">
                        </div>
                        <div className="promo-title">
                            <h1>ENSEIGNEMENT SUPÉRIEUR</h1>
                        </div>
                    </Link>
                    <Link to="/enseignement-qualifiant" className="promo" onClick={()=>handleClick('Formations')}>
                        <div id="qual" className="promo-img">
                        </div>
                        <div className="promo-title">
                            <h1>ENSEIGNEMENT QUALIFIANT</h1>
                        </div>
                    </Link>
                    <Link to="/formations" className="promo" onClick={()=>handleClick('Formations')}>
                        <div id="tous" className="promo-img">
                        </div>
                        <div className="promo-title">
                            <h1>TOUS LES COURS</h1>
                        </div>
                    </Link>
                    <Link to="/actualites" onClick={()=>handleClick('actualites')} id="actu"><img src="/images/news.svg" alt="actualité" /><h1>ACTUALITÉS</h1></Link>
                    <Link to="/informations" id="evenements" onClick={()=>handleClick('informations')}><img src="/images/calendar.svg" alt="calendrier" /><h1>CALENDRIER</h1></Link>
                    <Link to="/contact" id="contact" onClick={()=>handleClick('contact')}><img src="/images/contact.svg" alt="Contact" /><h1>CONTACT</h1></Link>
                </div>
            </div>

            
            <div id="home-actu">
                <h1>DERNIÈRES ACTUALITÉS</h1>
                {actu.map(ac => 
                    <Link to={`/actualites/${ac.id}`} key={ac.id} className="home-actu">
                        <div className="home-actu-title" dangerouslySetInnerHTML={{__html:ac.title.rendered}}></div>
                        <div className="home-actu-info">
                            <h3 dangerouslySetInnerHTML={{__html:ac.title.rendered}}></h3>
                            <article dangerouslySetInnerHTML={{__html:ac.excerpt.rendered}}></article>
                        </div>
                        <div className="home-actu-img">{(ac._embedded['wp:featuredmedia']) ?
                            <FeatureImage image={ac._embedded['wp:featuredmedia']} /> :
                            <img src="/images/logo_epse.png" alt=""/>
                       }</div>
                    </Link>    
                )}
            </div>

            <div id="info">
                <div id="pratiques">
                    <h2>Infos pratiques</h2>
                    <a href="https://www.google.be/maps/place/Enseignement+de+Promotion+Sociale+d'Enghien+(EPSE)/@50.6921197,4.0156275,17z/data=!4m5!3m4!1s0x47c3b220b60b9cc7:0x81b95959d74b3155!8m2!3d50.6921195!4d4.0178163">Rue du village, 50<br />7850 Marcq</a>
                    <a href="tel:+3223956023">02/395.60.23</a>
                    <a href="mailto:info@epse.be">info@epse.be</a>
                    <a href="https://www.facebook.com/EPSEMarcq/" className="social-inline" target="_blank"><img src="/images/ifface.svg" alt="Facebook EPSE" /></a>
                    <a href="https://www.instagram.com/e.p.s.e/" className="social-inline" target="_blank"><img src="/images/ifinstag.svg" alt="Instagram EPSE" /></a>
                    <a href="https://www.linkedin.com/school/epse/" className="social-inline" target="_blank"><img src="/images/iflink.svg" alt="LinkedIn EPSE" /></a>
                    <a href="https://vimeo.com/epsenghien" className="social-inline" target="_blank"><img src="/images/ifvimeo.svg" alt="Vimeo EPSE" /></a>
                    <a href="https://www.youtube.com/channel/UC33Fs4BdaHlEb5GfDoZzSsA?view_as=subscriber" className="social-inline" target="_blank"><img src="/images/ifyt.svg" alt="Youtube EPSE" /></a>
                </div>
                <a href="https://www.google.be/maps/place/Enseignement+de+Promotion+Sociale+d'Enghien+(EPSE)/@50.6921197,4.0156275,17z/data=!4m5!3m4!1s0x47c3b220b60b9cc7:0x81b95959d74b3155!8m2!3d50.6921195!4d4.0178163"></a>
            </div>

        </Fragment>
    );
}

export default HomePage;

/* 

    <div id="events">
                    <div className="event">
                        <h3>MAR</h3>
                        <h3>05</h3>
                        <div className="title">Lorem ipsum dolor sit amet consectetur.</div>
                        <div className="btn"><a href="#">En savoir plus</a></div>
                    </div>
                    <div className="event">
                        <h3>MAR</h3>
                        <h3>19</h3>
                        <div className="title">Lorem ipsum dolor sit amet consectetur.</div>
                        <div className="btn"><a href="#">En savoir plus</a></div>
                    </div>
                    <div className="event">
                        <h3>AVR</h3>
                        <h3>28</h3>
                        <div className="title">Lorem ipsum dolor sit amet consectetur.</div>
                        <div className="btn"><a href="#">En savoir plus</a></div>
                    </div>
                    <div className="event">
                        <h3>MAI</h3>
                        <h3>09</h3>
                        <div className="title">Journée Portes Ouvertes</div>
                        <div className="btn"><a href="#">En savoir plus</a></div>
                    </div>
                </div>
*/