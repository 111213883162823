import React, { Component,Fragment } from 'react';

class ShowCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    sortDate(tableau){
        /* sort ne fait pas une copie, il le modifie directement */
        tableau.sort((a,b)=>{
            var dateA = new Date(a.date), dateB = new Date(b.date)
            return dateA - dateB
        })
    }

    render() { 
        return ( 
            <Fragment>
                {
                     this.props.calendrier.map( (calendrier, key) => 
                     <div key={key} className="calendrier">
                         <h1>{calendrier.titre}</h1>
                         <table className="table table-calendrier">
                             <thead>
                                 <tr>
                                     <th>Date</th>
                                     <th>Évènement</th>
                                 </tr>
                             </thead>
                             <tbody>
                             {this.sortDate(calendrier.agendaDates)}
                             {calendrier.agendaDates.map((agendaDate, key)=>
                                 <tr key={key}>
                                     <td>{agendaDate.label}</td>
                                     <td>{agendaDate.event}</td> 
                                 </tr>
                             )}
                              </tbody>  
                         </table>  
                     </div>
                 )
                }
            </Fragment>
         );
    }
}
 
export default ShowCalendar;