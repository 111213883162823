import React, {Fragment, useEffect, useState, useContext} from 'react';
import axios from "axios"
import ShowFormations from "../components/ShowFormations"
import LoadingSpinner from "../components/LoadingSpinner"
import MenuContext from '../contexts/MenuContext';

const EnseignementSup = (props) => {

    const [categories, setCategories] = useState([]);
    const [myFormations, setMyFormations ] = useState([])
    const [loading, setLoading] = useState([])
    const {showtimeOn, setMenuSelect} = useContext(MenuContext)

    useEffect(() => {
        window.scrollTo(0,0)
        setLoading(true) 
        setMenuSelect('Formations')
        axios
        .get(`https://adminepse.jordanberti.com/api/categories/1/formations?visibility=yes`)
        .then(response => response.data['hydra:member'])
        .then(data => {
            setLoading(false)
            setMyFormations(data)
        })
        .catch(error => console.log(error.response)) 
         
                
    },[])


    return ( 
            <Fragment>
                <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                    <div className="container">
                        <h1>ENSEIGNEMENT SUPÉRIEUR DE TYPE COURT</h1>
                        <p><strong>Bachelier </strong>- 3 années d'études - 180 crédits</p>
                        <p><strong>Brevet d'Enseignement Supérieur </strong>- 2 années d'études - 120 crédits</p>
                    </div>
                </div>


                <div id="formations-home">
                    <div className="container ens">  
                        <div id="formats">
                            {loading ? <LoadingSpinner /> : <ShowFormations formations={myFormations}/>}
                        </div>
                    </div>
                
                </div>
        </Fragment>
     );
}
 
export default EnseignementSup;