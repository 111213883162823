import React, {useRef, useEffect, Fragment, useState} from 'react';
import {
    Link
  } from "react-router-dom";
import { toast } from 'react-toastify';
import Axios from 'axios';
import { useContext } from 'react';
import MenuContext from '../contexts/MenuContext';
const Header = (props) => {
    const {menuSelect, setMenuSelect, setShowtimeOn} = useContext(MenuContext)
    const myRef = useRef(null)
    const burger = useRef(null)
    const menuMobile = useRef(null)
    const [etat, setEtat] = useState(0)
    const [showTime, setShowTime] = useState({})

    const fetchShowTime = async () => {
        try{
            const data = await Axios.get(`https://adminepse.jordanberti.com/api/show_times/1`)
                                    .then(response=>response.data)
            setShowTime(data)
                                 
        }catch(error){
            //toast.error("impossible de charger showtime")
        }
    }

    const handleClick = (path) => {
        setMenuSelect(path)
    }

    useEffect(()=>{
       
        fetchShowTime()
        const contMenu = myRef.current.querySelectorAll('li a')
        const myBurger = burger.current
        const linksBurger = menuMobile.current.querySelectorAll('ul li a')

        for(const myLink of linksBurger){
            myLink.addEventListener('click',()=>{
             
                myBurger.classList.remove('activeMenu')
                menuMobile.current.classList.remove('open-menu')
                setEtat(0)
                window.scrollTo(0,0)
            })
        }

      
    
        for(const menu of contMenu){
      
           
            if(window.location.href === menu.href){
                myRef.current.querySelector('li .active').classList.remove('active')
                menu.classList.add('active')     
            }

           /* menu.addEventListener('click',()=>{
                const activeMenu = myRef.current.querySelector('li .active')
                if(activeMenu){
                    activeMenu.classList.remove('active')
                }
                menu.classList.add('active')
               
            }) */
        }

        if(window.location.pathname.includes('Formation')){
            myRef.current.querySelector('li .active').classList.remove('active')
            myRef.current.querySelector('#menuFormation').classList.add('active')
        }else if(window.location.pathname.includes('actualites')){
            myRef.current.querySelector('li .active').classList.remove('active')
            myRef.current.querySelector('#menuActu').classList.add('active')
        }else if(window.location.pathname.includes('evenements') || window.location.pathname.includes('emploi') || window.location.pathname.includes('legal') || window.location.pathname.includes('a-propos') || window.location.pathname.includes('partenaires') || window.location.pathname.includes('qualite')){
            myRef.current.querySelector('li .active').classList.remove('active')
        }
        
        myBurger.addEventListener('click',()=>{
            const myBurger = burger.current
        const linksBurger = menuMobile.current.querySelectorAll('ul li a')
            if(etat===0){
                myBurger.classList.add('activeMenu')
                menuMobile.current.classList.add('open-menu')
                setEtat(1)
            }else{
                myBurger.classList.remove('activeMenu')
                menuMobile.current.classList.remove('open-menu')
                setEtat(0)
            }
           
        })
        
    },[])
    // à changer

    useEffect(()=>{
        const myBurger = burger.current
        myBurger.addEventListener('click',()=>{
           
            if(etat===0){
                myBurger.classList.add('activeMenu')
                menuMobile.current.classList.add('open-menu')
                setEtat(1)
            }else{
                myBurger.classList.remove('activeMenu')
                menuMobile.current.classList.remove('open-menu')
                setEtat(0)
            }
           
        })
    },[etat])

    useEffect(()=>{
        const contMenu = myRef.current.querySelectorAll('li a')
        for(const menu of contMenu){
            if(menuSelect==="Formations"){
                let activeMenu = myRef.current.querySelector('li .active')
                if(activeMenu){
                    activeMenu.classList.remove('active')
                }
                myRef.current.querySelector('#menuFormation').classList.add('active')
            }else if(menuSelect==="home"){
                let activeMenu = myRef.current.querySelector('li .active')
                if(activeMenu){
                    activeMenu.classList.remove('active')
                }
                myRef.current.querySelector('#menuHome').classList.add('active')
            }else if(menuSelect==="inscription"){
                let activeMenu = myRef.current.querySelector('li .active')
                if(activeMenu){
                    activeMenu.classList.remove('active')
                }
                myRef.current.querySelector('#menuInscriptions').classList.add('active')
            }else if(menuSelect==="evenements" || menuSelect==="emploi" || menuSelect==="a-propos" || menuSelect==="legal" || menuSelect==="qualite" || menuSelect==="portes-ouvertes"){
                let activeMenu = myRef.current.querySelector('li .active')
                if(activeMenu){
                    activeMenu.classList.remove('active')
                }   
            }else if(menuSelect==="informations"){
                let activeMenu = myRef.current.querySelector('li .active')
                if(activeMenu){
                    activeMenu.classList.remove('active')
                }
                myRef.current.querySelector('#menuInfo').classList.add('active')
            }else if(menuSelect==="actualites"){
                let activeMenu = myRef.current.querySelector('li .active')
                if(activeMenu){
                    activeMenu.classList.remove('active')
                }
                myRef.current.querySelector('#menuActu').classList.add('active')
            }else if(menuSelect==="contact"){
                let activeMenu = myRef.current.querySelector('li .active')
                if(activeMenu){
                    activeMenu.classList.remove('active')
                }
                myRef.current.querySelector('#menuContact').classList.add('active')
            }

        }
       
    },[menuSelect])

    /* observer showTime envoyer le statut  */
    useEffect(()=>{
      
        setShowtimeOn(showTime.visibility)
        
    },[showTime])
   
    return (
        <Fragment>
            <div ref={menuMobile} className="menu-mobile">
                <ul className={showTime.visibility === "yes" ? "stosm" : ""}>
                    <li><Link to="/">Accueil</Link></li>
                    <li><Link to="/formations">Formations</Link></li>
                    <li><Link to="/inscription">Inscriptions</Link></li>
                    <li><Link to="/informations">Infos</Link></li>
                    <li><Link to="/actualites">Actualités</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><a href="https://moodle.myepse.be/login/index.php"><img src="/images/Moodle.svg" alt="logo de moodle EPSE" id="moodle" /> Intranet</a></li>
                </ul>
            </div>
            <header>
                {(showTime.visibility === "yes") && (
                    <div id="showTime">
                        <div className="container">
                            <span className="showTitle">{showTime.title}</span> <span className="showContent">{showTime.content}</span>&nbsp;
                            { (showTime.link) && (<span className="showLink"><a href={showTime.link}>En savoir plus</a></span> )}
                        </div>
                        <div id="header-social">
                            <a href="https://www.facebook.com/EPSEMarcq/" target="_blank"><img src="/images/facebook.svg" alt="EPSE Facebook" /></a>
                            <a href="https://www.instagram.com/e.p.s.e/" target="_blank"><img src="/images/insta.svg" alt="EPSE Instagram" /></a>
                            <a href="https://www.linkedin.com/school/epse/" target="_blank"><img src="/images/linked.svg" alt="EPSE LinkedIn" /></a>
                            <a href="https://vimeo.com/epsenghien" target="_blank"><img src="/images/vimeo.svg" alt="EPSE Vimeo" /></a>
                            <a href="https://www.youtube.com/channel/UC33Fs4BdaHlEb5GfDoZzSsA?view_as=subscriber" target="_blank"><img src="/images/youtube.svg" alt="EPSE YouTube" /></a>
                        </div>
                    </div>
                )}
            
                <div id="mobile">
               
                    <nav>
                        <Link to="/"><img src="/images/logo.svg" alt="logo de l'EPSE" id="mobile_logo" /></Link>
                        <div ref={burger} id="burger">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </nav>
                    {(showTime.visibility === "yes") && (
                    <div id="showTime">
                        <div className="container">
                            <span className="showTitle">{showTime.title}</span> <span className="showContent">{showTime.content}</span>&nbsp;
                            { (showTime.link) && (<span className="showLink"><a href={showTime.link}>En savoir plus</a></span> )}
                        </div>
                    </div>
                )}
                </div>
                <nav><ul ref={myRef}>
                    <li><Link className="mylogo" to="/" id="container-logo"><img src="/images/logo.svg" alt="logo de l'EPSE" id="logo" /></Link></li>
                    <li><Link className="active" to="/" id="menuHome"  onClick={()=>handleClick('home')}>ACCUEIL</Link></li>
                    <li><Link to="/formations" id="menuFormation"  onClick={()=>handleClick('Formations')}>FORMATIONS</Link></li>
                    <li><Link to="/inscription" id="menuInscriptions"  onClick={()=>handleClick('inscription')}>INSCRIPTIONS</Link></li>
                    <li><Link to="/informations" id="menuInfo"  onClick={()=>handleClick('informations')}>INFOS</Link></li>
                    <li><Link to="/actualites" id="menuActu"  onClick={()=>handleClick('actualites')}>ACTUALITÉS</Link></li>
                    <li><Link to="/contact" id="menuContact"  onClick={()=>handleClick('contact')}>CONTACT</Link></li>
                    <li><a href="https://moodle.myepse.be/login/index.php"><img src="/images/Moodle.svg" alt="logo de moodle EPSE" id="moodle" /> INTRANET</a></li>
                </ul></nav>
            </header>
        </Fragment> 
     );
}
 
export default Header;