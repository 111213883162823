import React, { useContext } from 'react'
import MenuContext from '../contexts/MenuContext';

const Legal = (props) => {
    const {showtimeOn} = useContext(MenuContext)
    window.scrollTo(0,0)
    return ( 
        <>
          <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>Mentions légales et politique de confidentialité</h1>
                    <p>L’enseignement de Promotion sociale d’Enghien (EPSE) est un établissement de promotion sociale reconnu et subsidié par la Fédération Wallonie-Bruxelles; membre de la Fédération des Etablissements Libres Subventionnés Indépendants, soucieuse des droits des individus, notamment au regard des traitements automatisés et dans une volonté de transparence, a mis en place une politique reprenant l’ensemble de ces traitements, des finalités poursuivies par ces derniers ainsi que des moyens d’actions à la disposition des individus afin qu’ils puissent au mieux exercer leurs droits. Pour toute information complémentaire sur la protection des données personnelles, nous vous invitons à consulter le site <a href="https://www.autoriteprotectiondonnees.be/">https://www.autoriteprotectiondonnees.be/</a></p>
                    <p>
                    La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et conditions d'utilisation qui suivent. La version actuellement en ligne de ces conditions d'utilisation est la seule opposable pendant toute la durée d'utilisation du site et jusqu'à ce qu'une nouvelle version la remplace.  
                    </p>

                </div>
            </div>
            <div className="page-body" >
                <div className="container" id="legal">
                    <h3>Mentions Légales</h3>
                    <ol>
                        <li>
                            <h4>Site</h4>
                            <p><a href="https://www.epse.be">epse.be</a></p>
                        </li>
                        <li>
                            <h4>Editeur</h4>
                            <p>Rue du village, 50 à 7850 Marcq</p>
                            <p>Tel: 02/395.60.23</p>
                            <p>E-mail: info@epse.be</p>
                            <p>IBAN: BE67 635-2157801-87</p>
                        </li>
                        <li>
                            <h4>Hébergeur</h4>
                            <p>
                            Epse.be est hébergé par <a href="https://www.phpnet.org/">PHPNET.org</a>, dont le siège social est situé 97-97 bis rue Général Mangin 38100 GRENOBLE
                            </p>
                        </li>
                    </ol>

                    <h3>Accès au site</h3>
                    <p>
                    L’accès au site et son utilisation sont réservés à usage strictement personnel. Vous vous engagez à ne pas utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politques, publicitaires et pour toute forme de sollicitation commerciale et notamment l’envoi de courriers électroniques non sollicités. 
                    </p>

                    <h3>Contenu du site</h3>
                    <p>
                    Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce site et plus généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la propriété intellectuelle. Ils sont la propriété pleine et entière de l’éditeur ou de ses partenaires. Toutes reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l’accord préalable et écrit de l’éditeur, sont strictement interdites. Le fait pour l’éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation des dites utilisations et renonciation aux poursuites.    
                    </p>

                    <h3>Gestion du site</h3>
                    <p>
                    Pour la bonne gestion du site, l'éditeur pourra à tout moment : - suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines parties du site, à une catégorie déterminée d'internautes ; - supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales ; - suspendre le site afin de procéder à des mises à jour. 
                    </p>

                    <h3>Responsabilités</h3>
                    <p>
                    La responsabilité de l'éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses fonctionnalités. Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d'attaques virales par Internet. Vous êtes par ailleurs seul responsable des sites et données que vous consultez.  
                    </p>
                    <p>L'éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre : </p>
                    <ul>
                        <li>du fait de l'usage du site ou de tout service accessible via Internet ;</li>
                        <li>du fait du non-respect par vous des présentes conditions générales.</li>
                    </ul>
                    <p>L'éditeur n'est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait. </p>
                    <p>Si l'éditeur venait à faire l'objet d'une procédure amiable ou judiciaire en raison de votre utilisation du site, il pourra se retourner contre vous pour obtenir l'indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.</p>

                    <h3>Liens hypertextes</h3>
                    <p>La mise en place par les utilisateurs de tous liens hypertextes vers tout ou partie du site est autorisée par l'éditeur. Tout lien devra être retiré sur simple demande de l'éditeur. Toute information accessible via un lien vers d'autres sites n'est pas publiée par l'éditeur. L'éditeur ne dispose d'aucun droit sur le contenu présent dans ledit lien.</p>

                    <h3>Collecte et protection des données</h3>
                    <p>Vos données sont collectées par <strong>L’enseignement de Promotion sociale d’Enghien (EPSE).</strong></p>
                    <p>
                    Une donnée à caractère personnel désigne toute information concernant une personne physique identifiée ou identifiable (personne concernée) ; est réputée identifiable une personne qui peut être identifiée, directement ou indirectement, notamment par référence à un nom, un numéro d'identification ou à un ou plusieurs éléments spécifiques, propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale. Les informations personnelles pouvant être recueillies sur le site sont principalement utilisées par l'éditeur pour la gestion des relations avec vous, et le cas échéant pour le traitement de vos demandes. 
                    </p>
                    <p>Les données personnelles collectées sont les suivantes : </p>
                    <ul>
                        <li>nom et prénom </li>
                        <li>adresse e-mail</li>
                    </ul>

                    <h3>Droit d’accès, de rectification et de déréférencement de vos données</h3>
                    <p>En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des droits suivants :</p>
                    <ul>
                        <li>le droit d’accès : ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant, en écrivant à l'adresse électronique suivante <a href='mailto:info.epse.be'>info.epse.be</a>. Dans ce cas, avant la mise en œuvre de ce droit, la Plateforme peut demander une preuve de l'identité de l'utilisateur afin d'en vérifier l'exactitude ;</li>
                        <li>le droit de rectification : si les données à caractère personnel détenues par la Plateforme sont inexactes, ils peuvent demander la mise à jour des informations ;</li>
                        <li>le droit de suppression des données : les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données ;</li>
                        <li>le droit à la limitation du traitement : les utilisateurs peuvent de demander à la Plateforme de limiter le traitement des données personnelles conformément aux hypothèses prévues par le RGPD ;</li>
                        <li>le droit de s’opposer au traitement des données : les utilisateurs peuvent s’opposer à ce que leurs données soient traitées conformément aux hypothèses prévues par le RGPD ;</li>
                        <li>le droit à la portabilité : ils peuvent réclamer que la Plateforme leur remette les données personnelles qu'ils ont fournies pour les transmettre à une nouvelle Plateforme.</li>
                    </ul>
                    <p>Vous pouvez exercer ce droit en nous contactant, à l’adresse suivante :</p> 
                    <p><strong>Rue du village, 50 à 7850 Marcq</strong></p>
                    <p>Ou par email, à l’adresse :</p>
                    <p><a href='mailto:info.epse.be'>info.epse.be</a></p>
                    <p>Toute demande doit être accompagnée de la photocopie d’un titre d’identité en cours de validité signé et faire mention de l’adresse à laquelle l'éditeur pourra contacter le demandeur. La réponse sera adressée dans le mois suivant la réception de la demande. Ce délai d'un mois peut être prolongé de deux mois si la complexité de la demande et/ou le nombre de demandes l'exigent.</p>

                    <h3>Utilisation des données</h3>
                    <p>Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services de la Plateforme, leur amélioration et le maintien d'un environnement sécurisé. La base légale des traitements est l’exécution du contrat entre l’utilisateur et la Plateforme. Plus précisément, les utilisations sont les suivantes :</p>
                    <ul>
                        <li>mise en œuvre d'une assistance pour les demandes ou questions posées par l'utilisateur</li>
                    </ul>

                    <h3>Politique de conservation des données</h3>
                    <p>La Plateforme conserve vos données pour la durée nécessaire pour vous fournir ses services ou son assistance.</p>
                    <p>Dans la mesure raisonnablement nécessaire ou requise pour satisfaire aux obligations légales ou réglementaires, régler des litiges, empêcher les fraudes et abus ou appliquer nos modalités et conditions, nous pouvons également conserver certaines de vos informations si nécessaire, même après que vous ayez fermé votre compte ou que nous n'ayons plus besoin pour vous fournir nos services.</p>

                    <h3>Cookies</h3>
                    <p>Qu’est-ce qu’un « cookie » ?</p>
                    <p>Un « Cookie » ou traceur est un fichier électronique déposé sur un terminal (ordinateur, tablette, smartphone,…) et lu par exemple lors de la consultation d'un site internet, de la lecture d'un courrier électronique, de l'installation ou de l'utilisation d'un logiciel ou d'une application mobile et ce, quel que soit le type de terminal utilisé.</p>
                    <p>En naviguant sur ce site, des « cookies » émanant de la société responsable du site concerné et/ou des sociétés tiers pourront être déposés sur votre terminal. Lors de la première navigation sur ce site, une bannière explicative sur l’utilisation des « cookies » apparaîtra. Dès lors, en poursuivant la navigation, le client et/ou prospect sera réputé informé et avoir accepté l’utilisation desdits « cookies ». Le consentement donné sera valable pour une période de 12 mois. L'utilisateur a la possibilité de désactiver les cookies à partir des paramètres de son navigateur.</p>
                    <p>Toutes les informations collectées ne seront utilisées que pour suivre le volume, le type et la configuration du trafic utilisant ce site, pour en développer la conception et l'agencement et à d'autres fins administratives et de planification et plus généralement pour améliorer le service que nous vous offrons.</p>
                    Pour plus d’informations sur l’utilisation, la gestion et la suppression des « cookies », pour tout type de navigateur, nous vous invitons à consulter le lien suivant : 
                    <a href="https://www.autoriteprotectiondonnees.be/citoyen/themes/internet/cookies">https://www.autoriteprotectiondonnees.be/citoyen/themes/internet/cookies</a>

                    <h3>Photographies et représentation des produits</h3>
                    <p>Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n'engagent pas l'éditeur.</p>

                    <h3>Loi applicable</h3>
                    <p>Tout litige en relation avec l’utilisation du site https://www.epse.be y est soumis au droit belge. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents de Belges.</p>

                    <h3>Contactez-nous</h3>
                    <p>Pour toute question, information sur les produits présentés sur le site, ou concernant le site lui-même, vous pouvez laisser un message à l'adresse suivante : <a href="mailto:info@epse.be">info@epse.be</a>.</p>

                </div>
            </div>   
        </>
     );
}
 
export default Legal;