import React, {Fragment, useEffect, useState, useContext} from 'react';
import axios from "axios"
import ShowFormations from "../components/ShowFormations"
import LoadingSpinner from "../components/LoadingSpinner"
import MenuContext from '../contexts/MenuContext';

const Formations = (props) => {
    
    const [categories, setCategories] = useState([]);
    const [myFormations, setMyFormations ] = useState([])
    const [catSearch, setCatSearch] = useState(0)
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState([])
    const {showtimeOn, setMenuSelect} = useContext(MenuContext)

    useEffect(() => {
    
        setLoading(true) 
        setMenuSelect('Formations')
        axios
            .get(`https://adminepse.jordanberti.com/api/categories`)
            .then(response => response.data['hydra:member'])
            .then(data => {
                setLoading(false)
                setCategories(data)
            })
            .catch(error=>console.log(error.response))
      
        if(catSearch===0){
            window.scrollTo(0,0)
            axios
            .get(`https://adminepse.jordanberti.com/api/formations?visibility=yes`)
            .then(response => response.data['hydra:member'])
            .then(data => {
                setLoading(false)
                setMyFormations(data)
            })
            .catch(error => console.log(error.response))
        }else{
            axios
            .get(`https://adminepse.jordanberti.com/api/categories/${catSearch}/formations?visibility=yes`)
            .then(response => response.data['hydra:member'])
            .then(data => {
                setLoading(false)
                setMyFormations(data)
            })
            .catch(error => console.log(error.response)) 
        }    
                
    }, [catSearch])

    const handlePageChange = (id,event) => {
        setCatSearch(id)
    }

    const handleSearch = event => {
        const value = event.currentTarget.value
        setSearch(value)
    }

    const filteredFormations = myFormations.filter(
        f => f.title.toLowerCase().includes(search.toLocaleLowerCase())
    )

    return ( 
        <Fragment>
            <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>NOS FORMATIONS</h1>
                    <p>Découvrez l'ensemble de nos formations</p>
                </div>
            </div>


            <div id="formations-home">
                <div className="container">  
                <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Recherchez une formation"/>
                   
                    <div id="categories">
                        <div className={"category " + ((catSearch === 0) ? "active" : false)} onClick={(event) => handlePageChange(0,event)}><span>TOUTES</span></div>
                        {
                            categories.map( (category, key) => 
                            <div key={key} onClick={(event) => handlePageChange(category.id,event)} className={"category " + ((catSearch === category.id) ? "active" : false)}><span>{category.title}</span></div>    
                            )
                        }
                    </div>
                   
                    <div id="formats">
                        {loading ? <LoadingSpinner /> : <ShowFormations formations={filteredFormations}/>}
                    </div>
                   
                </div>
               
            </div>
        </Fragment>
     );
}
 
export default Formations;