import React, { useState, useContext } from 'react'
import Axios from 'axios'
import { useEffect } from 'react'
import FeatureImage from '../components/FeatureImage'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Pagination from '../components/Pagination'
import LoadingSpinner from "../components/LoadingSpinner"
import { toast } from 'react-toastify'
import MenuContext from '../contexts/MenuContext'

const Actu = (props) => {

    const [articles, setArticles] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState([])
    const itemsPerPage = 10
    const {showtimeOn,setMenuSelect} = useContext(MenuContext)
    const fetchArticles = async () => {
        try{
            const data = await Axios.get("https://blogepse.jordanberti.com/wp-json/wp/v2/posts?_embed")
                                    .then(response=>response.data)
            setLoading(false)                        
            setArticles(data)                        
        }catch(error){
            toast.error("Un problème est survenu")
            props.history.replace('/')
        }
    }

    useEffect(()=>{
        setLoading(true)
        window.scrollTo(0,0)
        fetchArticles()
        setMenuSelect('actualites')
    },[])

    // gestion du changement de page
    const handlePageChange = (page) => {
        setCurrentPage(page)
        window.scrollTo(0,0)
    }

    const formatDate = (str) => moment(str).format('DD/MM/YYYY') 

    const paginatedActu = Pagination.getData(articles, currentPage, itemsPerPage)

    return ( 
        <>
            <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>Actualités</h1>
                    <p>Suivre toutes les actualités de l'EPSE</p>
                </div>
            </div>
            <div className="page-body" >
                <div className="container" id="actu-container">
                {
                  loading ? <LoadingSpinner /> :  
                (paginatedActu.map(article =>(
                    <Link to={`/actualites/${article.id}`} key={article.id} className="articles">
                        <div className="articles-title" dangerouslySetInnerHTML={{__html:article.title.rendered}}></div>
                       
                        <div className="articles-image">{(article._embedded['wp:featuredmedia']) ?
                            <FeatureImage image={article._embedded['wp:featuredmedia']} /> :
                            <img src="/images/logo_epse.png" alt=""/>
                       }</div>
                    </Link>
                    
                ) )
                )
                }
                </div>
                { itemsPerPage < articles.length && 
                    <Pagination 
                        currentPage={currentPage} 
                        itemsPerPage={itemsPerPage}
                        length={articles.length}
                        onPageChanged={handlePageChange}
                    />
                }        

            </div>
        </>
     );
}
 
export default Actu;