import React,{Fragment, useContext} from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Axios from 'axios'
import { useEffect } from 'react'
import MenuContext from '../contexts/MenuContext'

const Job = (props) => {
    var slug= props.match.params.slug
    const [job, setJob] = useState({
        id:"",
        title:"",
        section:"",
        content:"",
        slug:""    
    })
    const {showtimeOn} = useContext(MenuContext)
    const fetchJob = async (slug) => {
        try{
            const data = await Axios.get(`https://adminepse.jordanberti.com/api/jobs?slug=${slug}`)
                            .then(response => response.data["hydra:member"])
            setJob(data[0])                  
        }catch(error){
            toast.error('impossible de charger!')
            props.history.replace('/emploi')
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0)
        fetchJob(slug)
    },[slug])

    return ( 
        <>
            <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>Recrutement pour {job.section}</h1>
                    <p>{job.title}</p>
                    <Link to="/emploi">Retour aux offres</Link>
                </div>
            </div>
            <div className="page-body" >
                <div className="container" id="jobs">
                    <article id="content-job">
                        {job.content.split('\n').map((item, key) => {
                            return <Fragment key={key}>{item}<br/></Fragment>
                        })}
                    </article>
                    <p id="job-info">Si vous êtes intéressé(e)s, contactez-nous par mail à <a href="mailto:info@epse.be">info@epse.be</a> ou par téléphone au numéro <strong>02/395.60.23</strong></p>
                </div>
            </div>   
        </>
     );
}
 
export default Job;