import React from 'react'

const FeatureImage = (props) => {

    return ( 
        <>
            {props.image.map((image, key) => (
                <img key={key} src={image.source_url} alt="image de blogEPSE" />
            ))}
        </>
     );
}
 
export default FeatureImage;