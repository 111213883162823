import React, { useContext } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import MenuContext from '../contexts/MenuContext';
import '../wordpress.css';

const Article = (props) => {
    var id= props.match.params.id
    const {showtimeOn, setMenuSelect} = useContext(MenuContext)
    const [article, setArticle] = useState({
        title: {},
        date : "",
        content: {}
    })

    const fetchArticle = async id => {
        try{
            const {title, date, content} = await Axios.get(`https://blogepse.jordanberti.com/wp-json/wp/v2/posts/${id}?_embed`)
                                    .then(response=>response.data)
            setArticle({title, date, content})                        
        }catch(error){
            toast.error("Imposible de charger l'article")
            props.history.replace('/actualites')
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0)
        fetchArticle(id)
        setMenuSelect('actualites')
    },[id])

    return ( 
        <>
            <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")} id="article-home">
                <div className="container">
                    <h1 dangerouslySetInnerHTML={{__html:article.title.rendered}}></h1>
                    <Link to="/actualites">Retour aux articles</Link>
                </div>
                
            </div>
            <div className="page-body" id="article">
                <div className="container">
                    <article dangerouslySetInnerHTML={{__html:article.content.rendered}}></article>
                </div>
            </div>
            
           
        </>
     );
}
 
export default Article;