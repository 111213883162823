import React from 'react'

const Pagination = (props) => {

    const pagesCount = Math.ceil(props.length / props.itemsPerPage)
    const pages = []

    for(let i=1; i <= pagesCount; i++){
        pages.push(i)
    }

    return ( 
        <div className="pagination">
            <ul>
            {pages.map(page => (
                <li key={page} className={props.currentPage === page ? "is-active" : null} onClick={()=>props.onPageChanged(page)}>{page}</li>
            ))    
            }   
            </ul>
        </div>
     );
}

Pagination.getData = (items, currentPage, itemsPerPage) => {
    // permet de découper un tableau: arr.slice(début, fin)
    const start = currentPage * itemsPerPage - itemsPerPage
    //              3         *     10       -  10          =  20
    return items.slice(start, start + itemsPerPage)
}
 
export default Pagination;