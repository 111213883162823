import React,{useContext, useEffect} from 'react';
import MenuContext from '../contexts/MenuContext';

const PartnersPage = (props) => {
    const {showtimeOn} = useContext(MenuContext)
    useEffect(()=>{
        window.scrollTo(0,0)
        
    },[])
    return ( 
        <>
            <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>Nos Partenaires</h1>
                    <p>L'EPSE est un établissement de promotion sociale reconnu et subsidié par la Fédération Wallonie-Bruxelles; membre de la Fédération des Etablissements Libres Subventionnés Indépendants</p>
                </div>
            </div>
            <div className="page-body" >
                <div className="container" id="partners">
                    <div className="partners-logo-container">
                        <div className="partners-logo">
                            <a href="https://promsoc.cfwb.be" target='_blank' rel="noopener noreferrer">
                                <img src="/images/partners/eps_logo.png" alt="Logo EPS" />
                            </a>
                        </div>
                        <div className="partners-logo">
                            <a href="https://www.federation-wallonie-bruxelles.be" target='_blank' rel="noopener noreferrer">
                                <img src="/images/partners/FWB_HORI_RGB.png" alt="Logo Fédération Wallonie-Bruxelles" />
                            </a>
                        </div>
                        <div className="partners-logo">
                            <a href="https://www.leforem.be/contact/carrefour-emploi-formation-orientation/tournai.html" target='_blank' rel="noopener noreferrer">
                                <img src="/images/partners/LogoCM.png" alt="Logo Carrefour des Métiers" />
                            </a>
                        </div>
                        <div className="partners-logo">
                            <a href="https://www.cdmcharleroi.be" target='_blank' rel="noopener noreferrer">
                                <img src="/images/partners/LOGOCDMW.png" alt="Logo Les Cités des Métiers de Wallonie" />
                            </a>
                        </div>
                        <div className="partners-logo">
                            <a href="https://www.felsi.eu" target='_blank' rel="noopener noreferrer">
                                <img src="/images/partners/FELSI.png" alt="Logo FELSI" />
                            </a>    
                        </div>
                        <div className="partners-logo">
                            <a href="https://commission.europa.eu/strategy-and-policy/recovery-plan-europe_fr" target='_blank' rel="noopener noreferrer">
                                <img src="/images/partners/logo-RRF.png" alt="Logo Financé par l'Union européenne" />
                            </a>
                        </div>
                        <div className="partners-logo">
                            <a href="https://www.mirewapi.be" target='_blank' rel="noopener noreferrer">
                                <img src="/images/partners/MireWapi.png" alt="Logo MIRe WaPi" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>    
        </>
     );
}
 
export default PartnersPage;