import React, { useState, useContext } from 'react'
import { toast } from 'react-toastify';
import Axios from 'axios';
import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment'
import MenuContext from '../contexts/MenuContext';

const Event = (props) => {
    var id= props.match.params.id

    const [event, setEvent ] = useState({
        id:"",
        title:"",
        date:"",
        link:"",
        content:""
    });
    const {showtimeOn} = useContext(MenuContext)
    const fetchEvent = async id => {
        try{
            const data = await Axios.get(`https://adminepse.jordanberti.com/api/events/${id}`)
                                .then(response => response.data)
            setEvent(data)    
        }catch(error){
            toast.error('impossible de charger l\'évenement')
            props.history.replace('/')
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        fetchEvent(id)
    },[id])

    const formatDate = (str) => moment(str).format('DD/MM/YYYY') 

    return ( 
        <>
             <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")} id="article-home">
                <div className="container">
                    <h1>{event.title}</h1>
                    <h3>{formatDate(event.date)}</h3>
                    <Link to="/evenements">Retour aux événements</Link>
                </div>
                
            </div>
            <div className="page-body" id="article">
                <div className="container">
                    {event.link && <a href={event.link}>{event.link}</a>}
                    <article>{event.content}</article>
                </div>
            </div>
        </>
     );
}
 
export default Event;