import React, {useEffect, useState, useContext} from 'react'
import {toast} from "react-toastify";
import Axios from "axios";
import MenuContext from '../contexts/MenuContext';

const Qualites = (props) => {
    const [qualite, setQualite] = useState({
        id:"",
        title:"",
        content:"",
        pdf:""
    })
    const {showtimeOn} = useContext(MenuContext)
    const fetchQualite = async () =>{
        try{
            const data = await Axios.get("https://adminepse.jordanberti.com/api/qualities/1")
                .then(response => response.data)
            setQualite(data)
        }
        catch(error){
            toast.error("Impossible de charger Service qualité")
            props.history.replace("/")
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0)
        fetchQualite()
    },[])

    return ( 
        <>
            <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>Qualité</h1>
                    <p>L'EPSE est un établissement de promotion sociale reconnu et subsidié par la Fédération Wallonie-Bruxelles; membre de la Fédération des Etablissements Libres Subventionnés Indépendants</p>
                </div>
            </div>
            <div className="page-body" >
                <div className="container" id="qualite">
                    <h3>L’AEQES (Agence pour l’Evaluation de la Qualité de l’Enseignement Supérieur)</h3>
                    <blockquote cite="https://www.aeqes.be/agence.cfm">
                        <p>L'AEQES est une agence de service public, indépendante, qui pratique une évaluation formative de programmes d'enseignement supérieur organisés en Fédération Wallonie-Bruxelles ; elle rend compte de la qualité de l'enseignement supérieur et œuvre à son amélioration constante. Ce faisant, elle vise à encourager le développement d'une culture qualité dans les établissements, la clarification des profils d'enseignement et des objectifs des formations en lien avec les missions des établissements, à diffuser des bonnes pratiques et à favoriser la mise en place de synergies entre les parties prenantes de l'enseignement supérieur.</p>
                        <p>En développant son positionnement au niveau européen et international, en nouant des collaborations internationales, l'Agence contribue à consolider la visibilité internationale de l'enseignement supérieur de la FWB.</p>
                        <p>L’Agence publie sur son site internet les rapports finaux de synthèse, les analyses transversales, les rapports de suivi et un relevé des bonnes pratiques.</p>
                        <p className="source">source: <cite>aeqes.be</cite></p>
                    </blockquote>
                    <h3>La qualité dans l'enseignement de Promotion sociale</h3>
                    <blockquote>
                        <p>La culture qualité de l’enseignement de Promotion sociale se décline au travers de grands principes tels que :</p>
                        <ul>
                            <li>la citoyenneté active ;</li>
                            <li>une dynamique de formation tout au long de la vie s’adressant à des publics diversifiés et aux parcours différents ;</li>
                            <li>l’ouverture aux autres et aux changements ;</li>
                            <li>un parcours d’études souple et personnalisé grâce à la modularisation ;</li>
                            <li>la reconnaissance possible des capacités acquises ;</li>
                            <li>l’orientation et la guidance, la remédiation ;</li>
                            <li>les programmes officiels exprimés en termes d’acquis d’apprentissage ;</li>
                            <li>la capitalisation pour la mobilité des étudiants ;</li>
                            <li>l’expertise actualisée des chargés de cours ;</li>
                            <li>la formation en cours de carrière des membres du personnel ;</li>
                            <li>l’ouverture aux nouvelles technologies ;</li>
                            <li>la mise en place de partenariats.</li>
                        </ul>
                        <p className='source'><cite>Extrait du « Guide pour la gestion de la qualité dans l’EPS »</cite></p>
                    </blockquote>
                    <h3>La qualité à l'EPSE</h3>
                    <p><strong>Plus concrètement, à l’Epse la qualité</strong> c’est la garantie d’un enseignement dispensé par des chargés de cours passionnés ainsi que des experts issus du monde professionnel. C’est également l’assurance d’un encadrement et d’un suivi individuel des étudiants par l’ensemble de l’équipe de l’EPSE (direction, enseignants, membres du personnel administratif) tout au long de leur formation et même au-delà.</p>
                    <p>N’hésitez pas à consulter ci-dessous le dernier rapport final d’évaluation de la section BES en Weddeveloper. </p>
                    <h3>Les ECTS dans l’enseignement supérieur</h3>
                    <p>Le système européen de transfert et d’accumulation de crédits (ECTS) est un outil de l’espace européen de l’enseignement supérieur. Il a été développé dans le cadre du processus de Bologne et permet notamment la mobilité des étudiants au sein de pays de l’Union Européenne.</p>
                    <p>Les crédits acquis auprès d’un établissement qui dispense de l’enseignement supérieur peuvent être comptabilisés pour l’obtention d’une qualification dans un autre établissement. Les crédits ECTS représentent un apprentissage fondé sur des acquis d’apprentissage clairement définis ainsi que sur la charge de travail qui leur est associée.</p>
                    <h3>La valeur d’un ECTS</h3>
                    <p>Un crédit (ECTS) correspond à 25 - 30 heures de travail (travail en classe + travail personnel). A chaque unité d’enseignement d’un cursus supérieur correspond un nombre d’ECTS déterminé.</p>
                    <p>La formation de bachelier en techniques graphiques comprend 180 ECTS répartis sur 3 années d’études.</p>
                    <p>Le cursus du Brevet d’enseignement supérieur (BES) en webdeveloper, organisé sur deux années scolaires, compte 120 ECTS.</p>
                    <h3 className="qualite-title">{qualite.title}</h3>
                    <div className="qualite-content">{qualite.content}</div>
                    <div className="pdf">
                        <a href={`https://adminepse.jordanberti.com/uploads/pdf/${qualite.pdf}`} className="infos-file">DOCUMENTATION PDF <i className="fas fa-download"></i></a>
                    </div>
                    <div className="qualite-content">BES Webdeveloper Plan d'action Mars 2023: </div>
                    <div className="pdf">
                        <a href="EPSE-BESWebdeveloper-Plan_d_action-Mars2023.pdf" className="infos-file">DOCUMENTATION PDF <i className="fas fa-download"></i></a>
                    </div>
                    <div className="qualite-content">BES Webdeveloper Rapport d'évaluation: </div>
                    <div className="pdf">
                        <a href="RAPPORT_DEFINITIF_POSTE_SITE_AEQES_25112022.pdf" className='infos-file'>DOCUMENTATION PDF <i className="fas fa-download"></i></a>
                    </div>
                </div>
            </div> 
        </>
     );
}
 
export default Qualites;