import React, {Fragment, useEffect, useState, useContext} from 'react';
import axios from "axios"
import ShowCalendar from '../components/ShowCalendar'
import LoadingSpinner from "../components/LoadingSpinner"
import ShowInfosPdf from "../components/ShowInfosPdf"
import MenuContext from '../contexts/MenuContext';

const Informations = (props) => {
    const [calendrier, setCalendrier] = useState([])
    const [info, setInfo] = useState([])
    const [loadingCalendar, setLoadingCalendar] = useState([])
    const [loadingInfo, setLoadingInfo] = useState([])
    const {showtimeOn, setMenuSelect} = useContext(MenuContext)

    useEffect(() => { 
        window.scrollTo(0,0)
        setMenuSelect('informations')
        setLoadingInfo(true) 
        setLoadingCalendar(true)
        axios
            .get(`https://adminepse.jordanberti.com/api/agendas?visibility=yes`)
            .then(response => response.data['hydra:member'])
            .then(data => {
                setLoadingCalendar(false)
                setCalendrier(data)
            })
            .catch(error => console.log(error.response))

        axios
            .get(`https://adminepse.jordanberti.com/api/information`)
            .then(response => response.data['hydra:member'])
            .then(data => {
                setLoadingInfo(false)
                setInfo(data)
            })
            .catch(error => console.log(error.response))  
    },[]);
    
   
 
    return ( 
        <Fragment>
           <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>INFORMATIONS</h1>
                    <p>Pour toute information complémentaire, n'hésitez pas à nous téléphoner au <strong>02/395.60.23</strong></p>
                </div>
            </div>
            <div className="page-body" id="info-page-body">
                <div className="container">
                    {loadingCalendar ? <LoadingSpinner /> : <ShowCalendar calendrier={calendrier}/>}
                    {loadingInfo ? <LoadingSpinner/> : <ShowInfosPdf pdfs={info}/>} 
                </div>
            </div> 
        </Fragment>
     );
}
 
export default Informations;